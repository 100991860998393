import Base from "@/api-services/base";
const BaseURL = `Colaboradores`;
import PeriodosNominas from "@/api-services/periodosNominas.service";
import { IsoDate2sFechaAmplia } from "@/api-services/LiFecha";
export default {
  GetsResponse: null,
  GetsResponseBorrados: null,
  Listado: [],
  getsSelect(Vista, Borrados, OnCorrecto, OnErroneo, OnFinalizado) {
    if (Borrados) {
      if (this.GetsResponse != null) OnCorrecto(this.GetsResponse);
    } else {
      if (this.GetsResponseBorrados != null)
        OnCorrecto(this.GetsResponseBorrados);
    }
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      { PaginaLen: 1000, Borrados: Borrados },
      (response) => {
        if (Borrados) this.GetsResponseBorrados = response;
        else this.GetsResponse = response;
        this.Listado = response.data.listado;
        if (OnCorrecto != undefined) OnCorrecto(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
  TotalesPorGets(Vista, Agrupacion, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/TotalesPor/${Agrupacion}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  putCamposLaborales(
    Vista,
    CamposLaborales,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/CamposLaborales`);
    return Base.put(
      Vista,
      URL,
      CamposLaborales,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  putCamposPersonales(
    Vista,
    CamposPersonales,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/CamposPersonales`);
    return Base.put(
      Vista,
      URL,
      CamposPersonales,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  putAutoServicioConfig(
    Vista,
    CamposLaborales,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/AutoServicioConfig`);
    return Base.put(
      Vista,
      URL,
      CamposLaborales,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  AniversariosGets(Vista, UbicacionID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Aniversarios/${UbicacionID}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Suscripciones
  CumpleanosGets(Vista, UbicacionID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Cumpleanos/${UbicacionID}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //quita lo borrado
  patch(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(
      Vista,
      URL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
    //return Base.patchBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  FiltroGets(Vista, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Filtro`,
      Params,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  FiltroListadoGets(Vista, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Filtro/Listado`,
      Params,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsEnriquecido(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Enriquecido`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsOrganigrama(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Organigrama`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  FotoURL(Colaborador) {
    var R = "/img/default-avatar.png";
    // console.debug(Colaborador);
    if (
      Colaborador != undefined &&
      Colaborador != null &&
      Colaborador.urlMiniatura != undefined &&
      Colaborador.urlMiniatura != null &&
      Colaborador.urlMiniatura != ""
    ) {
      R = Colaborador.urlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    if (
      Colaborador != undefined &&
      Colaborador != null &&
      Colaborador.UrlMiniatura != undefined &&
      Colaborador.UrlMiniatura != null &&
      Colaborador.UrlMiniatura != ""
    ) {
      R = Colaborador.UrlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    //console.debug(R);
    return R;
  },
  //Obtiene el archivo de layout para la suscripción activa
  InterfazGet(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Interfaz`);
    if (OnCorrecto == null) {
      OnCorrecto = (response) => {
        Base.Response2FileDownload(response);
      };
    }
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: BaseGetsListado },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Obtiene un archivo en excel previamente configurado en la suscripción
  ExcelAvanzadoGets(
    Vista,
    Formato,
    BaseGetsListado,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Avanzado/${Formato}`);
    if (OnCorrecto == null) {
      OnCorrecto = (response) => {
        Base.Response2FileDownload(response);
      };
    }
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: BaseGetsListado },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Obtiene el archivo de layout para la suscripción activa
  ExcelGet(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel`);
    if (OnCorrecto == null) {
      OnCorrecto = (response) => {
        Base.Response2FileDownload(response);
      };
    }
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: BaseGetsListado },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Obtiene la plantilla de importaicón para la suscripción activa
  ExcelPlantillaGet(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Plantilla`);
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: { EsXls: false } },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Importa un archivo de excel
  ExcelPlantillaPatch(
    Vista,
    Archivo,
    OnUploadProgress,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    let formData = new FormData();
    formData.append("ArchivoExcel", Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel`);
    return Base.patch(
      Vista,
      URL,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: OnUploadProgress,
      },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Obtiene la plantilla de importaicón de historicos para la suscripción activa
  ExcelPlantillaHistoricosGet(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/PlantillaHistoricos`);
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: { EsXls: false } },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Importa un archivo de excel de historicos
  ExcelPlantillaHistoricosPatch(
    Vista,
    Archivo,
    OnUploadProgress,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    let formData = new FormData();
    formData.append("ArchivoExcel", Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Historicos`);
    return Base.patch(
      Vista,
      URL,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: OnUploadProgress,
      },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  //Importa desde interfaz
  InterfazPatch(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Interfaz`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Envia una foto para su identificacion
  IdentificarPatch(
    Vista,
    Archivo,
    OnUploadProgress,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    let formData = new FormData();
    formData.append("Foto", Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Identificar`);
    return Base.patch(
      Vista,
      URL,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: OnUploadProgress,
      },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  //Agrega o actualiza la foto de un colaborador
  FotoPatch(
    Vista,
    ID,
    Orden,
    Archivo,
    OnUploadProgress,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    let formData = new FormData();
    formData.append("Foto", Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Foto?Orden=${Orden}`);
    return Base.patch(
      Vista,
      URL,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: OnUploadProgress,
      },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  postClones(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Clones`);
    return Base.post(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado, SuscripcionID) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado,
      SuscripcionID
    );
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  delete(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Borrar`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  EnviarBienvenida(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/EnviarBienvenida`);
    return Base.post(
      Vista,
      URL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  PostSesion(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Sesiones`);
    return Base.post(
      Vista,
      URL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getAceptarInvitacion(
    Vista,
    SuscripcionID,
    ColaboradorID,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = `${Base.URL}/Suscripciones/${SuscripcionID}/${BaseURL}/${ColaboradorID}/AceptarInvitacion`;
    //  console.debug(URL);
    return Base.get(Vista, URL, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getInvitacion(Vista, ColaboradorID, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(
      `${BaseURL}/${ColaboradorID}/Invitacion`
    );
    return Base.get(Vista, URL, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getHome(Vista, id, Parametros, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}/Home`,
      Parametros,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetHuellas(Vista, ColaboradorID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${ColaboradorID}/Huellas`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getByCURP(Vista, CURP, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/CURP/${CURP}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  MotivosBaja() {
    return [
      {
        label: "Separación voluntaria, mejor oferta económica/desarrollo",
        value: "Crecimiento",
      },
      {
        label: "Separación voluntaria, ambiente de trabajo",
        value: "Ambiente",
      },
      { label: "Separación voluntaria, relación con jefe", value: "Jefe" },
      {
        label: "Separación voluntaria, cambio de residencia o le queda lejos",
        value: "Ubicacion",
      },
      {
        label:
          "Rescisión de contrato, no es la persona para el puesto nunca perteneció",
        value: "Encaje",
      },
      {
        label: "Rescisión de contrato Término de contrato",
        value: "FinContrato",
      },

      { label: "Rescisión de contrato Abandono el empleo", value: "Abandono" },
      { label: "Rescisión de contrato Bajo desempeño", value: "Desempeno" },
      { label: "Se jubiló", value: "Jubilacion" },

      { label: "Otro", value: "Otro" },
      { label: "Desconocido", value: "Desconocido" },
    ];
  },
  MotivoBaja(value) {
    var R = this.MotivosBaja().filter((x) => x.value == value);
    if (R.length > 0) return R[0].label;
    return "Desconocido";
  },
  Periodo: {
    ListadoColaboradores: [],
    Filtros: null,
    valorFiltro: null,
    periodo: null,
    Colaboradores: [],
    PeriodoTexto: "",
    Filtro: "",
    ElFiltro: {
      Borrados: false,
      PaginaNo: 0,
      PaginaLen: 50,
      filtro: "",
    },
    //La ultima fecha obtenida de los periodos de nómina
    Hoy: null,
    PeriodosNominas: [],
  },
  PeriodoValoresObtener(Vista, ObtenerPeriodosNominas) {
    if (Vista) {
      Vista.ListadoColaboradores = this.Periodo.ListadoColaboradores;
      Vista.Filtros = this.Periodo.Filtros;
      Vista.valorFiltro = this.Periodo.valorFiltro;
      Vista.periodo = this.Periodo.periodo;
      Vista.Colaboradores = this.Periodo.Colaboradores;
      Vista.PeriodoTexto = this.Periodo.PeriodoTexto;
      Vista.Filtro = this.Periodo.Filtro;
      if (ObtenerPeriodosNominas) {
        var Hoy = IsoDate2sFechaAmplia();
        if (Hoy != this.Periodo.Hoy) {

          PeriodosNominas.gets(Vista, { Activos: true }, (response) => {
            this.Periodo.PeriodosNominas = response.data.listado;
      //      console.debug(response);
            Vista.PeriodosNominas = this.Periodo.PeriodosNominas;
            this.Periodo.Hoy = Hoy;
          });
        } else Vista.PeriodosNominas = this.Periodo.PeriodosNominas;
      }
    }
    return this.Periodo.ElFiltro;
  },
  PeriodoValoresGuarda(Vista, ElFiltro) {
    this.Periodo.ListadoColaboradores = Vista.ListadoColaboradores;
    this.Periodo.Filtros = Vista.Filtros;
    this.Periodo.valorFiltro = Vista.valorFiltro;
    this.Periodo.periodo = Vista.periodo;
    this.Periodo.Colaboradores = Vista.Colaboradores;
    this.Periodo.PeriodoTexto = Vista.PeriodoTexto;
    this.Periodo.Filtro = Vista.Filtro;
    this.Periodo.ElFiltro = ElFiltro;
  },
};
